import { getCookie } from "../utils/utils";

export default class WishListHeader extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.sync = this.getAttribute("sync");
    this.csrfToken = getCookie("csrftoken");
    this.wishlistIconEmpty = this.querySelector("#wishlist-icon-empty");
    this.wishlistIconFilled = this.querySelector("#wishlist-icon-filled");
    this.wishlistIconCount = this.querySelector("#wishlist-icon-count");

    window.addEventListener("wishlist-changed", this.updateWishlist.bind(this));
  }

  updateWishlist(event) {
    if (this.sync === "true") {
      if (event.detail.isWishlisted) {
        const result = this.addToWishlist(event);
        result.then((data) => {
          this.updateStatus(data);
        });
      } else {
        const result = this.removeFromWishlist(event);
        result.then((data) => {
          this.updateStatus(data);
        });
      }
    } else {
      this.updateStatus(event.detail);
    }
  }

  async addToWishlist(event) {
    const headers = new Headers({
      "X-CSRFToken": this.csrfToken,
    });
    const data = new FormData();
    if (event.detail.productGroupId) {
      data.append("product_group_id", event.detail.productGroupId);
    }

    const response = await fetch("/wishlist/add/", {
      method: "POST",
      headers: headers,
      body: data,
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Failed to add to wishlist");
    }
  }

  async removeFromWishlist(event) {
    const headers = new Headers({
      "X-CSRFToken": this.csrfToken,
    });
    const data = new FormData();
    if (event.detail.productGroupId) {
      data.append("product_group_id", event.detail.productGroupId);
    }
    const response = await fetch("/wishlist/remove/", {
      method: "POST",
      headers: headers,
      body: data,
    });
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Failed to remove from wishlist");
    }
  }

  updateStatus(data) {
    if (this.sync === "true") {
      if (data.count > 0) {
        this.wishlistIconEmpty.classList.add("hidden");
        this.wishlistIconFilled.classList.remove("hidden");
        this.wishlistIconCount.textContent = data.count;
        this.wishlistIconCount.parentElement.classList.remove("hidden");
      } else {
        this.wishlistIconEmpty.classList.remove("hidden");
        this.wishlistIconFilled.classList.add("hidden");
        this.wishlistIconCount.textContent = "";
        this.wishlistIconCount.parentElement.classList.add("hidden");
      }
    } else {
      if (data.isWishlisted) {
        this.wishlistIconEmpty.classList.add("hidden");
        this.wishlistIconFilled.classList.remove("hidden");
        this.wishlistIconCount.textContent =
          +this.wishlistIconCount.textContent + 1;
        this.wishlistIconCount.parentElement.classList.remove("hidden");
      } else {
        this.wishlistIconCount.textContent =
          +this.wishlistIconCount.textContent - 1;
        if (this.wishlistIconCount.textContent === "0") {
          this.wishlistIconEmpty.classList.remove("hidden");
          this.wishlistIconFilled.classList.add("hidden");
          this.wishlistIconCount.textContent = "";
          this.wishlistIconCount.parentElement.classList.add("hidden");
        }
      }
    }
  }
}
window.customElements.define("wishlist-header", WishListHeader);
