export class ProductCard extends HTMLElement {
  static observedAttributes = ["wishlist"];

  constructor() {
    super();
  }

  connectedCallback() {
    // Get Attributes & Elements
    this.detailUrl = this.getAttribute("detail-url");
    this.productGroupId = this.dataset.groupItemId;
    this.wishlistButton = this.querySelector(".wishlist-button");
    this.heartIconEmpty = this.querySelector(".heart-icon-empty");
    this.heartIconFilled = this.querySelector(".heart-icon-filled");

    // Add Event Listener
    this.addEventListener("click", this.onClick);
    this.wishlistButton.addEventListener(
      "click",
      this.toggleWishlist.bind(this)
    );
  }

  onClick() {
    window.location.href = this.detailUrl;
  }

  toggleWishlist(event) {
    event.stopPropagation();
    if (this.getAttribute("wishlist") === "true") {
      this.setAttribute("wishlist", "false");
    } else {
      this.setAttribute("wishlist", "true");
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "wishlist") {
      if (!this.heartIconEmpty || !this.heartIconFilled) {
        return;
      }
      if (newValue === "true") {
        this.heartIconEmpty.classList.add("hidden");
        this.heartIconFilled.classList.remove("hidden");
        this.dispatchEvent(
          new CustomEvent("wishlist-changed", {
            detail: {
              productId: "",
              productGroupId: this.productGroupId,
              isWishlisted: true,
            },
            bubbles: true,
          })
        );
      } else {
        this.heartIconEmpty.classList.remove("hidden");
        this.heartIconFilled.classList.add("hidden");
        this.dispatchEvent(
          new CustomEvent("wishlist-changed", {
            detail: {
              productGroupId: this.productGroupId,
              isWishlisted: false,
            },
            bubbles: true,
          })
        );
      }
    }
  }
}
window.customElements.define("product-card", ProductCard);
