export class Sort extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.sortInput = document.querySelector('input[name="sort"]');
        this.sortOptionsContainer = this.querySelector('sort-options');
        this.sortOptions = this.sortOptionsContainer.querySelectorAll('sort-option');
        this.btn = this.querySelector("button");

        // Add event listener to the button
        this.btn.addEventListener("click", this.togglesortOptionsContainer.bind(this));

        // Add event listener to the document
        document.addEventListener("click", this.closesortOptionsContainer.bind(this));

        // Add event listener to the sort options
        this.sortOptions.forEach(option => {
            option.addEventListener("click", this.setSortValue.bind(this));
        });
    }

    togglesortOptionsContainer() {
        this.sortOptionsContainer.classList.toggle("hidden");
    }

    closesortOptionsContainer(event) {
        if (!this.contains(event.target)) {
            this.sortOptionsContainer.classList.add("hidden");
        }
    }

    setSortValue(event) {
        if (this.sortInput.value !== event.target.dataset.value) {
            this.sortInput.value = event.target.dataset.value;
            this.sortOptionsContainer.classList.add("hidden");
            this.sortInput.dispatchEvent(
                new CustomEvent("filterChange", {
                    detail: {
                        name: this.sortInput.name,
                        value: this.sortInput.value,
                    },
                    bubbles: true,
                }
                )
            );
        }
    }
}
window.customElements.define('sort-menu', Sort);