export class FilterContainer extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.formElement = this.querySelector("form");
    this.closeToggle = this.querySelector("#closeToggle");
    this.resetToggle = this.querySelector("#resetToggle");
    this.submitFiltersBtn = this.querySelector("#submitFiltersBtn");

    // Event listener for the form element
    this.addEventListener("filterChange", this.handleFilterChange);

    // Event listener for the close toggle element
    this.closeToggle.addEventListener("click", this.handleCloseToggleClick);

    // Event listener for the reset filters button element
    this.resetToggle.addEventListener("click", this.resetFilters);

    // Event listener for the submit filters button element
    this.submitFiltersBtn.addEventListener("click", this.handleFilterChange);

    // Event listener for the open filter menu event
    window.addEventListener("openFilterMenu", this.handleOpenFilterMenu);
  }

  handleFilterChange = (event) => {
    this.formElement.submit();
  };

  handleOpenFilterMenu = (event) => {
    // Toggle the visibility of the filter container
    this.classList.add("translate-x-0");
    this.classList.remove("-translate-x-full");
  };

  handleCloseToggleClick = (event) => {
    // Toggle the visibility of the filter container
    this.classList.add("-translate-x-full");
    this.classList.remove("translate-x-0");
  };

  resetFilters() {
    const currentUrl = new URL(window.location.href);
    currentUrl.search = "";
    const newUrl = currentUrl.toString();
    window.location.href = newUrl;
  }
}

window.customElements.define("filter-container", FilterContainer);
