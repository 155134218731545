import { getCookie } from "../utils/utils";

export default class ProductDetailsContainer extends HTMLElement {
  static observedAttributes = ["wishlist"];

  constructor() {
    super();
  }

  connectedCallback() {
    this.csrfToken = getCookie("csrftoken");
    this.productId = this.getAttribute("product-id");
    this.productGroupId = this.getAttribute("product-group-id");
    this.mobileBtnHeartEmpty = document.querySelector(
      "#mobile-btn-heart-icon-empty"
    );
    this.mobileBtnHeartFilled = document.querySelector(
      "#mobile-btn-heart-icon-filled"
    );
    this.btnHeartEmpty = this.querySelector("#btn-heart-icon-empty");
    this.btnHeartFilled = this.querySelector("#btn-heart-icon-filled");

    // Track the impression of the product details page
    this.trackEvent("impression-product-details");

    // Add Evemt Listeners
    this.btnHeartEmpty.addEventListener(
      "click",
      this.toggleWishlist.bind(this)
    );
    this.btnHeartFilled.addEventListener(
      "click",
      this.toggleWishlist.bind(this)
    );
    this.mobileBtnHeartEmpty.addEventListener(
      "click",
      this.toggleWishlist.bind(this)
    );
    this.mobileBtnHeartFilled.addEventListener(
      "click",
      this.toggleWishlist.bind(this)
    );
  }

  trackEvent(eventName) {
    const beaconUrl = `/events/${eventName}/`;
    const formData = new FormData();
    formData.append("product_id", this.productId);
    formData.append("csrfmiddlewaretoken", this.csrfToken);

    navigator.sendBeacon(beaconUrl, formData);
  }

  toggleWishlist(event) {
    event.stopPropagation();
    if (this.getAttribute("wishlist") === "true") {
      this.setAttribute("wishlist", "false");
    } else {
      this.setAttribute("wishlist", "true");
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "wishlist") {
      if (!this.mobileBtnHeartEmpty || !this.mobileBtnHeartFilled) {
        return;
      }
      if (newValue === "true") {
        this.mobileBtnHeartEmpty.classList.add("hidden");
        this.mobileBtnHeartFilled.classList.remove("hidden");
        this.btnHeartEmpty.classList.add("hidden");
        this.btnHeartFilled.classList.remove("hidden");
        this.dispatchEvent(
          new CustomEvent("wishlist-changed", {
            detail: {
              productId: "",
              productGroupId: this.productGroupId,
              isWishlisted: true,
            },
            bubbles: true,
          })
        );
      } else {
        this.mobileBtnHeartEmpty.classList.remove("hidden");
        this.mobileBtnHeartFilled.classList.add("hidden");
        this.btnHeartEmpty.classList.remove("hidden");
        this.btnHeartFilled.classList.add("hidden");
        this.dispatchEvent(
          new CustomEvent("wishlist-changed", {
            detail: {
              productId: "",
              productGroupId: this.productGroupId,
              isWishlisted: false,
            },
            bubbles: true,
          })
        );
      }
    }
  }
}
window.customElements.define(
  "product-details-container",
  ProductDetailsContainer
);
