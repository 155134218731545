import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

export class ProductImageGallery extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.splideOptions = {
      type: "slide",
      perPage: 1,
      perMove: 1,
      pagination: true,
      arrows: true,
      drag: true,
      snaps: true,
    };

    this.splide = new Splide(this, this.splideOptions);
    this.splide.mount();
  }
}
window.customElements.define("product-image-gallery", ProductImageGallery);
