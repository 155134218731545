class BaseFilter extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    this.filterContent = this.querySelector("#filter-content");
    this.toggleOpen = this.querySelector("#toggle-open");
    this.toggleClose = this.querySelector("#toggle-close");
    this.isDisabled = this.hasAttribute("disabled");

    // Add event listeners to the toggle buttons
    this.toggleOpen.addEventListener("click", this.openFilter);
    this.toggleClose.addEventListener("click", this.closeFilter);
  }

  openFilter = () => {
    this.filterContent.classList.remove("hidden");
    this.toggleOpen.classList.add("hidden");
    this.toggleClose.classList.remove("hidden");
  };

  closeFilter = () => {
    this.filterContent.classList.add("hidden");
    this.toggleOpen.classList.remove("hidden");
    this.toggleClose.classList.add("hidden");
  };
}

export class CategoricalFilter extends BaseFilter {
  connectedCallback() {
    super.connectedCallback();

    // Get the search input and form inputs
    this.searchInput = this.querySelector("input[type='search']");
    this.formInputs = this.querySelectorAll("input[type='checkbox']");

    // Add event listener to the search input
    this.searchInput.addEventListener("input", this.handleSearchInput);

    // Add event listener to the form inputs
    this.formInputs.forEach((input) => {
      input.addEventListener("change", this.handleInputChange);
    });
  }

  handleSearchInput = (event) => {
    const value = event.target.value.toLowerCase();
    this.formInputs.forEach((input) => {
      const label = input.nextElementSibling.textContent.toLowerCase();
      if (label.includes(value)) {
        input.parentElement.classList.remove("hidden");
      } else {
        input.parentElement.classList.add("hidden");
      }
    });
  };

  handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    this.dispatchEvent(
      new CustomEvent("filterChange", {
        detail: { name, value, checked },
        bubbles: true,
      })
    );
  };
}
window.customElements.define("categorical-filter", CategoricalFilter);

export class BooleanFilter extends BaseFilter {
  connectedCallback() {
    super.connectedCallback();

    // Get the button and hidden input
    this.toggleButton = this.querySelector("button[role='switch']");
    this.toggleButtonSpan = this.toggleButton.querySelector("span");
    this.hiddenInput = this.querySelector("input[type='hidden']");

    // Add event listener to the button
    if (!this.isDisabled) {
      this.toggleButton.addEventListener("click", this.handleButtonClick);
    }
  }

  handleButtonClick = () => {
    // Get the current value of the hidden input
    const currentValue = this.hiddenInput.value;

    // Toggle the value of the hidden input
    this.hiddenInput.value = currentValue === "true" ? "false" : "true";

    // Update button css classes
    this.toggleButton.classList.toggle("bg-gray-200");
    this.toggleButton.classList.toggle("bg-blue-600");
    this.toggleButtonSpan.classList.toggle("translate-x-5");
    this.toggleButtonSpan.classList.toggle("translate-x-0");

    // Dispatch the filterChange event
    this.dispatchEvent(
      new CustomEvent("filterChange", {
        detail: {
          name: this.hiddenInput.name,
          value: this.hiddenInput.value,
        },
        bubbles: true,
      })
    );
  };
}
window.customElements.define("boolean-filter", BooleanFilter);

export class PriceFilter extends BaseFilter {
  connectedCallback() {
    super.connectedCallback();

    // Get the min and max inputs
    this.minInput = this.querySelectorAll("input")[0];
    this.maxInput = this.querySelectorAll("input")[1];

    // Add event listener to the inputs
    this.minInput.addEventListener("change", this.handleInputChange);
    this.maxInput.addEventListener("change", this.handleInputChange);
  }

  handleInputChange = () => {
    const min = this.minInput.value;
    const max = this.maxInput.value;

    this.dispatchEvent(
      new CustomEvent("filterChange", {
        detail: {
          name: this.getAttribute("filter-name"),
          value: { min, max },
        },
        bubbles: true,
      })
    );
  };
}
window.customElements.define("price-filter", PriceFilter);
