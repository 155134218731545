export class ActiveFilter extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.addEventListener("click", this.handleClick);
  }

  handleClick(e) {
    const filterType = this.getAttribute("type");

    if (filterType === "categorical") {
      const filterInput = document.querySelector(
        "categorical-filter input[name='" +
          this.getAttribute("name") +
          "'][value='" +
          this.getAttribute("value") +
          "']"
      );
      if (filterInput) {
        filterInput.checked = false;
        this.triggerFilterChange();
      }
    } else if (filterType === "boolean") {
      const filterInput = document.querySelector(
        "boolean-filter input[name='" + this.getAttribute("name") + "']"
      );
      if (filterInput) {
        filterInput.value = "";
        this.triggerFilterChange();
      }
    } else if (filterType === "range") {
      const filterInput = document.querySelector(
        "form input[name='" + this.getAttribute("name") + "']"
      );
      if (filterInput) {
        filterInput.value = "";
        this.triggerFilterChange();
      }
    }
  }

  triggerFilterChange() {
    this.dispatchEvent(
      new CustomEvent("filterChange", {
        bubbles: true,
      })
    );
  }
}
window.customElements.define("active-filter", ActiveFilter);
